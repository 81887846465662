$(function() {
    function toggleOrderType(transfer) {
        var toFieldPlaceholder =
            $('#select2-t_select-container > .select2-selection__placeholder');
        var toAddressFieldPlaceholder =
            $('#select2-t_address_select-container > ' +
              '.select2-selection__placeholder');

        if (transfer) {
            $('#tr').val('transfer');
            $('.transfer-fields').removeClass('hidden');
            $('.rent-fields').addClass('hidden');

            toFieldPlaceholder.html(
                $('#t_select').data('placeholder-transfer'));
            toAddressFieldPlaceholder.html(
                $('#t_address_select').data('placeholder-transfer'));
        } else {
            $('#tr').val('rent');
            $('.transfer-fields').addClass('hidden');
            $('.rent-fields').removeClass('hidden');

            toFieldPlaceholder.html($('#t_select').data('placeholder-rent'));
            toAddressFieldPlaceholder.html(
                $('#t_address_select').data('placeholder-rent'));
        }
    }

    $('#transfer-button').click(function () {
        toggleOrderType(true);
    });

    $('#rent-button').click(function () {
        toggleOrderType(false);
    });
})
